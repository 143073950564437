.container {
  position: static;
}

.frame {
  position: relative;
  background-color: #f5f5f5;
}

.frame > * {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 0;
}

.droparea {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 3px dashed #cfcfcf;
}

.droparea.dragging,
.droparea:focus {
  border-color: #7f7f7f;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
}

.instructions [data-element="button"] {
  margin-top: 8px;
}

.instructions svg {
  display: block;
}

.remoteImage img {
  display: block;
  width: 100%;
  height: auto;
}

.modal {
  position: relative;
  background-color: white;
  max-height: 100%;
  max-width: 100vw;
  box-shadow: var(--modal-box-shadow);
  text-align: center;
  font-size: 1px;
}

.instructions {
  padding: 16px;
}

.instructions h3 {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
}

.cropperContainer {
  display: block;
  max-width: 1200px;
}

.modalActions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 16px;
}

.modalActions > *:first-child {
  margin-top: 16px;
}

.cropperContainer img {
  max-height: calc(100vh - 250px);
  animation: cropSelection 0.5s ease-out;
}

.cropperContainer :global(.ReactCrop__crop-selection) {
  animation: cropSelection 0.8s ease-out 1 0.7s backwards;
}

@keyframes cropSelection {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 480px) {
  .modalActions {
    flex-direction: row;
    padding: 32px;
  }

  .modalActions > *:first-child {
    margin: 0 16px 0 0;
  }
}

.modalActions [data-element="button"] {
  padding: 0 32px;
  width: 200px;
}

@media (min-width: 600px) {
  .modal {
    margin: 0 64px;
  }

  .instructions {
    padding: 32px;
  }
}

:global(.ReactCrop__crop-selection) {
  will-change: transform;
  border: 2px dashed #fff;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.5s ease, border-color 0.5s ease;
}

:global(.ReactCrop__drag-handle) {
  background-color: #cd003e;
}

:global(.ReactCrop--disabled .ReactCrop__crop-selection) {
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.75);
  border-color: transparent;
}
