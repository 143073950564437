.button {
  position: relative;
  display: inline-block;
  font-size: 15px;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-weight: bold;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
