.tooltip {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  background: rgba(127, 127, 127, 1);
  color: white;
  font-size: 14px;
  z-index: -1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  animation: tooltipAppear 0.25s ease 1 0.1s backwards;
  max-width: calc(100vw - 64px);
}

.content {
  position: relative;
  max-width: 300px;
  padding: 12px;
}

@keyframes tooltipAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
