.container {
  position: relative;
  background: var(--placeholder-color);
  overflow: hidden;
}

.map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.75s ease;
}
