.facebook[data-element="button"] {
  color: #fff;
}

.facebook [data-element="background"] {
  background-image: linear-gradient(to right, #3b5998, #3b5998);
}

.facebook [data-element="icon"] path {
  stroke: #fff;
}

.facebook [data-element="loader"] circle {
  fill: #fff;
}
