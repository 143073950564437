.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  min-height: 44px;
  transition: transform 0.15s ease;
}

.icon svg {
  position: relative;
}

.icon:focus,
.icon:hover {
  transform: scale(1.06);
}

.icon:active {
  transform: scale(0.95);
}
