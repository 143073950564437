.container {
  position: absolute;
  padding: 2px 0 4px;
  font-size: 12px;
  text-align: left;
  max-width: 256px;
}

.error {
  position: relative;
  color: #e18807;
  font-weight: bold;
  line-height: 1.3;
}

@media (min-width: 400px) {
  .container {
    max-width: 320px;
  }
}
