.captcha {
  position: relative;
  display: flex;
  justify-content: center;
}

.captcha:empty {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  width: 256px;
  height: 60px;
  content: "";
  background: #dedede;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.captcha:empty::after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  animation: captcha 1s ease infinite;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.43) 40%,
    rgba(255, 255, 255, 0.43) 60%,
    transparent
  );
}

@keyframes captcha {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}
