.container {
  position: static;
  font-size: 16px;
}

.container.admin input[type] {
  border-color: var(--admin-color);
}

.container.admin input[type]:focus {
  border-color: var(--admin-color);
}

.container.admin [data-element="field-badge"] {
  color: var(--admin-color);
  border-color: var(--admin-color);
}

.container.admin [data-element="field-label"] {
  color: var(--admin-color);
}

.field.inline {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}

.field.inline .label + * {
  margin-top: 0;
  margin-right: 10px;
}

.field.inline .extra {
  margin-bottom: 0;
}

.field textarea,
.field input {
  appearance: none;
}

.extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.extra:empty {
  margin-bottom: 0;
}

.extra label {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #7f7f7f;
  user-select: none;
}

.input {
  font-family: "Raleway", sans-serif;
}

:global(.input-field),
.field textarea,
.field input[type] {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 14px 16px;
  border-radius: 0;
  border: 1px solid #bfbfbf;
  background: white;
  font-family: inherit;
  outline: 0;
  font-family: "Raleway", sans-serif;
}

:global(.input-field):focus,
.field textarea:focus,
.field input[type]:focus {
  border-color: #282e34;
}

:global(.input-field):read-only,
.field textarea:read-only,
.field input[type]:read-only {
  color: #c5c5c5;
  border-color: #eaeaea;
  cursor: not-allowed;
}

.container[data-readonly="true"] [data-element="field-label"] {
  opacity: 0.5;
}

.field textarea {
  min-height: 200px;
  resize: vertical;
  line-height: 1.5;
}

.field textarea::placeholder,
.field input::placeholder {
  color: #bfbfbf;
}

:global(.input-field:disabled),
.field input:disabled {
  background: #fbfbfb;
}

.help {
  position: relative;
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
}

.help * + * {
  margin-left: 8px;
}

.helpButton {
  position: relative;
  width: 20px;
  height: 15px;
}

.helpButton > div {
  position: absolute;
  top: -6px;
}

.container.hasError textarea,
.container.hasError input[type] {
  border-color: var(--error-color);
}
