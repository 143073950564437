.events .event-list {
  margin-top: 1.5rem;
}

.events .events-load-more {
  margin: 2em 0;
  text-align: center;
  min-height: 44px;
}

.events .event-list a {
  color: inherit;
}

.events .event-list .Card {
  margin-bottom: 10px;
}

.events .create-event {
  padding: 10%;
  width: 100%;
  border: dashed 4px #cc1140;
  text-align: center;
}

.events .create-event .Button {
  display: inline-block;
  line-height: 44px;
  text-decoration: none;
}

.events .create-event__image {
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  height: 135px;
}

.events .create-event__mode {
  display: block;
  margin: 2em 0 1em;
}
