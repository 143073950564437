.Tab {
	padding: 0.5em 1em;
	border-bottom: 1px solid #d8d8d8;
	text-transform: uppercase;
	cursor: pointer;
	font-family: var(--heading-font);
}

.Tab--active {
	border-bottom-color: var(--cta-color);
	color: var(--cta-color);
}

.Tab:not(.Tab--active):hover {
	border-bottom-color: #363636;
	color: #363636;
}
