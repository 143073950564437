.container {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
}

.container button {
  font-size: 14px;
  padding: 0;
}
