.portal {
  position: fixed;
  z-index: 101;
}

.backdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
  outline: 0;
}
