.container {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 10;
  overflow: auto;
  width: 100%;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  max-height: 160px;
  background: white;
  -webkit-overflow-scrolling: touch;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  border-left: 4px solid transparent;
}

.dropdown li:hover {
  border-left-color: #cc003e;
  background-color: #f5f5f5;
}

.dropdown li.disabled {
  display: none;
}
