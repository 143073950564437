.container {
  padding: 2px 4px 1px;
  color: #7f7f7f;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  animation: appear 0.75s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
