.fieldset {
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border: 0;
  appearance: none;
}

.legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 0 16px;
  font-weight: bold;
  border-bottom: 1px solid #ebebeb;
  color: #282e34;
  font-size: 18px;
  outline: 0;
  font-family: "Raleway", sans-serif;
  line-height: 1;
}

.children {
  margin-top: 40px;
}

.icon svg {
  transition: transform 0.3s ease-out;
  transform: rotate(-180deg);
}

.fieldset.collapsed .icon svg {
  transform: rotate(0deg);
}

.fieldset.collapsed svg polygon {
  fill: #000;
  transition: fill 0.3s ease;
}

.legend:focus svg polygon {
  fill: #cc003e;
}

.icon {
  display: flex;
  transition: transform 0.15s ease;
}

.legend:focus .icon {
  transform: scale(1.5);
}

.fieldset p {
  margin: 0;
}
