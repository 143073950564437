:global(.react-datepicker-popper) {
  z-index: 10;
  width: 100%;
  min-width: 256px;
}

:global(.react-datepicker) {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 16px;
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: normal;
}

:global(.react-datepicker__month-container) {
  position: relative;
}

:global(.react-datepicker__input-container) {
  position: relative;
  display: block;
}

:global(.react-datepicker__navigation) {
  position: absolute;
  border: 0;
  background: transparent;
  width: 64px;
  height: 44px;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  top: 8px;
  padding: 0;
  z-index: 1;
  text-indent: -999em;
  overflow: hidden;
}
:global(.react-datepicker__navigation)::after {
  position: absolute;
  background: none;
  top: 50%;
  transform: translate(0, -50%);
  height: 16px;
  width: 16px;
  border: 8px solid transparent;
  content: "";
}
:global(.react-datepicker__navigation--prev) {
  left: 0;
}
:global(.react-datepicker__navigation--next) {
  right: 0;
}

:global(.react-datepicker__navigation--previous::after) {
  left: 16px;
  border-right-color: #ccc;
}
:global(.react-datepicker__navigation--previous:hover::after) {
  border-right-color: #000;
}

:global(.react-datepicker__navigation--next::after) {
  right: 16px;
  border-left-color: #ccc;
}
:global(.react-datepicker__navigation--next:hover::after) {
  border-left-color: #000;
}

:global(.react-datepicker__month-container) {
  position: relative;
}

:global(.react-datepicker__header) {
  text-align: center;
  color: #000;
  font-weight: bold;
}

:global(.react-datepicker__month) {
  text-align: center;
}

:global(.react-datepicker__day-names),
:global(.react-datepicker__week) {
  display: flex;
}

:global(.react-datepicker__day-name),
:global(.react-datepicker__day) {
  flex-basis: 14.27%;
  height: 32px;
  line-height: 32px;
}

:global(.react-datepicker__day):hover {
  outline: 1px dashed #efefef;
  cursor: pointer;
  background-color: #efefef;
  color: black;
  border-radius: 3px;
}

:global(.react-datepicker__day--selected),
:global(.react-datepicker__day--keyboard-selected),
:global(.react-datepicker__month-text--keyboard-selected) {
  border-radius: 3px;
  background-color: #2a87d0;
  color: white;
}

:global(.react-datepicker__current-month) {
  margin: 4px 0 24px;
}

:global(.react-datepicker__day--outside-month) {
  opacity: 0.5;
}

:global(.react-datepicker__day--disabled) {
  opacity: 0.2;
}

:global(.react-datepicker__time-list-item--disabled) {
  display: none;
}
