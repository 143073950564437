.container {
  position: relative;
  font-size: 16px;
  margin-bottom: 120px;
}

.header {
  margin: 0 auto;
  padding: 32px;
  max-width: 600px;
  text-align: left;
  line-height: 1.4;
}

.header h1,
.header h2,
.header h3,
.header h4,
.header h5 {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
}

.header h1 + *,
.header h2 + *,
.header h3 + *,
.header h4 + *,
.header h5 + * {
  margin-top: 6px;
}

.children {
  position: relative;
  box-shadow: 0 -8px 12px rgba(0, 0, 0, 0.03);
  border-top: 2px dashed #ebebeb;
}

@media (min-width: 365px) {
  .header {
    text-align: center;
  }
}

@media (min-width: 600px) {
  .header {
    padding: 64px 32px;
  }
}
