.wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 var(--padding-page);
  max-width: calc(320px + var(--padding-page) * 2);
  min-width: 300px;
  font-size: 16px;
}

.wrapper > * + *,
.wrapper [data-element="field-toggle"],
.wrapper [data-element="field"] {
  margin-top: 38px;
}

.wrapper [data-element="field-toggle"] [data-element="field"]:first-child {
  margin-top: 16px;
}

.wrapper.wide > * + * {
  margin-top: inherit;
}

.wrapper form > :first-child {
  margin-top: 32px;
}

.wrapper fieldset {
  margin-top: 64px;
}

.wrapper [data-element="button-container"]:not([data-size="small"]) {
  width: 100%;
}

.wrapper.wide {
  max-width: calc(600px + var(--padding-page) * 2);
}

.wrapper form > :last-child {
  margin-top: 50px;
}
