.Button--Primary {
  height: 38px;
  line-height: 38px;
  padding: 0 2em;
  border-width: 1px;
  color: var(--cta-color);
  border: 1px solid var(--cta-color);
}

.Button--Dropdown {
  color: transparent;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.Button--Icon {
  width: 24px;
  height: 24px;
}

.Button--Icon:hover {
  opacity: 0.6;
}

.Button--Dropdown:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.Button--Ticket-shop {
  background: var(--cta-color);
  color: white;
  padding: 1em 2em;
}

.Button--Delete {
  background: black;
  color: white;
  padding: 0 2em;
}

.Button--Text {
  color: var(--cta-color);
  text-decoration: underline;
  font-weight: bold;
  padding: 0 2em;
  text-transform: none;
  font-size: inherit;
}

.Button--Submit {
  color: white;
  padding: 0 2em;
  font-weight: bold;
  background-image: var(--button-gradient-primary);
}

.Button--Submit:hover {
  color: white;
}

.Button--Submit__Loader-container {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease;
}

.Button--Submit--loading .Button--Submit__Loader-container {
  opacity: 1;
}

.Button--Submit--loading .Button__Children {
  opacity: 0;
}

.Button--Submit:disabled {
  background: var(--disabled-color);
}

.Button--Copy {
  position: relative;
  background-image: var(--button-gradient-primary);
  color: white;
  padding: 0 2em;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
}

.Button--Copy svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: auto;
  opacity: 0;
}

.Button--Copy span {
  transition: opacity 0.5s ease;
}

.Button--Copy--animating span {
  opacity: 0;
}

.Button--Copy--animating svg {
  opacity: 1;
  animation: copy-button-animation 2s ease-out 1 forwards;
}

@keyframes copy-button-animation {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }

  20% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }

  80% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
