.portal {
  position: fixed;
  max-width: 360px;
  bottom: 16px;
  left: 16px;
  z-index: 10;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
  font-size: 12px;
}

@media (max-width: 600px) {
  .portal {
    display: none;
  }
}

.panel {
  position: relative;
}

.portal h3 {
  font-weight: bold;
  font-family: inherit;
  margin-bottom: 8px;
}

.portal h3:not(:first-child) {
  margin-top: 1em;
}

.portal table {
  width: 100%;
}

.portal table td {
  border-top: 1px solid #efefef;
  padding: 4px 0;
  min-width: 100px;
}

.portal table td:last-child {
  padding-left: 16px;
  text-align: right;
}

.expand {
  position: relative;
  margin-top: 16px;
  font-size: 24px;
  line-height: 12px;
  padding: 4px;
}
