.radio-button {
  position: relative;
  font-size: inherit;
}

.radio-button label {
  display: block;
  padding-left: 28px;
  min-height: 20px;
  line-height: 20px;
}

.radio-button label::before,
.radio-button label::after {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #d8d8d8;
  content: '';
}

.radio-button label::after {
  left: 5px;
  width: 10px;
  height: 10px;
  background: var(--cta-color);
  transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  opacity: 0;
  transform: translate(0, -50%) scale(0);
}

.radio-button input:checked + label::after {
  transform: translate(0, -50%) scale(1);
  opacity: 1;
}

.radio-button input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
