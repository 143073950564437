.container {
  display: flex;
  height: 60px;
}

.container button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.container button > * {
  display: block;
  margin-left: 10px;
}

.container svg:last-child {
  transition: transform 0.2s ease;
  transform: translate3d(0, 1px, 0);
}

.container:hover svg path {
  stroke: "red";
}

.container:hover svg:last-child {
  transform: translate3d(0, 2px, 0);
}

.container:hover svg:last-child path {
  stroke: #cc003e;
}

/* .container:hover button {
  color: #cc003e;
} */

.container:hover svg:last-child {
  animation: jump 0.25s ease 1;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 2px, 0);
  }

  50% {
    transform: translate3d(0, 4px, 0);
  }

  100% {
    transform: translate3d(0, 2px, 0);
  }
}
