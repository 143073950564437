.container {
  line-height: 1;
}

.children {
  margin-top: 16px;
}

.children:empty {
  margin-top: 0;
}

.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
