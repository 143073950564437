.register {
  text-align: center;
}

.forgotPassword {
  position: relative;
  margin-top: 32px;
  text-align: center;
  line-height: 1;
}

.forgotPassword a {
  color: #cc003e;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
}
