:root {
  --admin-color: #3273dc;
  --error-color: #e18807;
  --cta-color: #cc003e;
  --disabled-color: #6e6e6e;
  --text-color-lighter: #7e8285;
  --text-color-darker: #282e34;
  --text-color-darkest: #000;
  --progress-bar-active-color: #48b9d3;
  --body-font: "Raleway", sans-serif;
  --heading-font: "Montserrat", sans-serif;
  --modal-box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  --highest-z-index: 2147483647;
  --next-highest-z-index: 2147483646;
  --button-gradient-primary: linear-gradient(to right, #cb003e, #e63120);
  --color-input-border: #bfbfbf;
  --color-input-border-focus: #282e34;
  --padding-page: 32px;
  --placeholder-color: #f5f5f5;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

.page {
  padding: 64px 32px 0;
}

.page-description {
  margin: 0 auto 32px;
  font-size: 16px;
  text-align: center;
}
