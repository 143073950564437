.container {
  position: static;
}

.optionsContainer {
  position: relative;
  font-size: 16px;
  margin: 16px 0 0;
}

.optionsContainer .parent .parent {
  margin-left: 16px;
  font-weight: normal;
}

.optionsContainer li {
  margin-top: 16px;
  line-height: 16px;
}

.parent {
  padding-bottom: 16px;
}

.parent h3 {
  font-weight: bold;
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 16px;
  line-height: 16px;
  margin-bottom: 16px;
}

.selectedOptions {
  margin-bottom: 16px;
}

.selectedOptions:empty {
  margin-bottom: 0;
}

.selectedOption {
  position: relative;
  padding: 16px;
  border: 1px solid red;
}

.noResults {
  font-size: 16px;
}

.item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
}

.item img {
  display: block;
  margin-right: 8px;
  width: 24px;
  height: auto;
}
