.helpButton {
  position: relative;
  padding: 0;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #cc003e;
  border: 0;
  font-weight: bold;
  appearance: none;
  outline: 0;
  background: transparent;
  font-family: sans-serif;
  background-image: linear-gradient(to right, #cc003e, #ff2000);
  transition: transform 0.1s ease;
  cursor: pointer;
}

.helpButton:focus,
.helpButton:hover {
  transform: scale(1.2);
}

.helpButton.open {
  transform: scale(1.2);
  color: white;
}

.helpButton.open::before {
  opacity: 0;
}

.helpButton::after,
.helpButton::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  content: "";
}

.helpButton::after {
  content: "?";
  line-height: 16px;
  background-color: transparent;
}
