.container {
  position: relative;
  background: white;
  font-family: var(--body-font);
}

.container input[type="text"] {
  border: 0;
  min-width: 130px;
}

.selectedContainer {
  position: relative;
}

.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  height: 49px;
  background-color: #efefef;
  border-radius: 3px;
  font-weight: normal;
  line-height: 1;
}

.selected + .selected {
  margin-top: 8px;
}

.selected button {
  min-height: 20px;
  min-width: 20px;
}

.selected button svg {
  width: 12px;
}

.selected button svg path {
  stroke: black;
}

.container input[type="text"] {
  position: relative;
  flex: 1;
  display: block;
  width: 100%;
  min-width: 100%;
  font-size: 16px;
  padding: 14px 16px;
  border-radius: 0;
  border: 0;
  background: transparent;
  font-family: inherit;
  outline: 0;
  appearance: none;
  border: 1px solid #bfbfbf;
}

.suggestions {
  position: absolute;
  width: 100%;
  z-index: 1;
  background: white;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow: auto;
}

.suggestions ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestions li {
  padding: 0;
  padding: 14px 16px;
}
