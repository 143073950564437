.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: blockLoader 1s ease-in-out infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 40%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
}

@keyframes blockLoader {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.line {
  display: inline-block;
  width: 100%;
  background: var(--placeholder-color);
  height: 16px;
}
