.container {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.box {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
  border: solid 1px #bfbfbf;
}

.box svg {
  position: absolute;
  display: block;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  transition: opacity 0.2s ease;
}

.container input {
  position: absolute;
  opacity: 0;
}

.container label {
  position: relative;
  padding-left: 28px;
  user-select: none;
}

.container input:focus + .box {
  border-color: #282e34;
}

.container input:checked + .box svg {
  opacity: 1;
}

.container input:checked + .box + label {
  color: #282e34;
}
