.Progress-bar {
  display: block;
  height: 4px;
  width: 100%;
  appearance: none;
}

.Progress-bar[value]::-moz-progress-bar {
  background-color: #cccccc;
}

.Progress-bar[value]::-webkit-progress-bar {
  background-color: #cccccc;
}

.Progress-bar[value] {
  background-color: #cccccc;
}

.Progress-bar::-moz-progress-value {
  background-color: var(--progress-bar-active-color);
}

.Progress-bar::-webkit-progress-value {
  background-color: var(--progress-bar-active-color);
}

.Progress-bar[value] {
  background-color: var(--progress-bar-active-color);
}

.Progress-bar--disabled::-moz-progress-value {
  background-color: var(--progress-bar-disabled-color);
}

.Progress-bar--disabled::-webkit-progress-value {
  background-color: var(--progress-bar-disabled-color);
}

.Progress-bar--disabled[value] {
  background-color: var(--progress-bar-disabled-color);
}
