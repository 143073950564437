.primary[data-element="button"] {
  color: #fff;
}

.primary [data-element="background"] {
  background-image: linear-gradient(to right, #cc003e, #ff2000);
}

.primary [data-element="icon"] path {
  stroke: #fff;
}

.primary [data-element="loader"] circle {
  fill: #fff;
}
