.container {
  position: relative;
  display: block;
}

.loader {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.loader > svg {
  width: 20px;
  animation: loader-dots 0.9s infinite ease;
  will-change: transform;
  opacity: 0;
  transform: scale(0);
  margin: 0 2px;
}

.container[data-size="small"] .loader > svg {
  width: 10px;
}

.loader > svg:nth-child(2) {
  animation-delay: 0.3s;
}

.loader > svg:nth-child(3) {
  animation-delay: 0.6s;
}

.circle {
  fill: #cc003e;
}

@keyframes loader-dots {
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
