.Loader {
  position: relative;
  pointer-events: none;
  will-change: transform;
  font-size: inherit;
}

.Loader--default {
  margin: 0 auto;
  width: 3em;
  height: 3em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #cc003e;
  border-radius: 50%;
  animation: LoaderSpin 1s ease-out infinite;
}

.Loader--dots {
  text-align: center;
  white-space: nowrap;
}

.Loader--dots svg {
  display: inline-block;
  width: 1em;
  margin: 0 0.25em;
  vertical-align: middle;
  animation: dots-loader 1s infinite cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: -0s;
}

.Loader--dots svg circle {
  fill: var(--cta-color);
}

.Loader--dots svg:first-child {
  animation-delay: -0.25s;
}

.Loader--dots svg:last-child {
  animation-delay: 0.25s;
}

@keyframes LoaderSpin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dots-loader {
  0%,
  80%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  40% {
    transform: scale(0);
    opacity: 0;
  }
}
