.container {
  margin: 0;
  padding: 0;
  min-width: 200px;
  overflow: hidden;
  background: white;
}

.item > * {
  position: relative;
  display: block;
  padding: 16px 32px;
  font-size: 16px;
  background-color: white;
  border-bottom: solid 1px #ebebeb;
  color: #4a4a4a;
}

.item > *:hover {
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.item > *::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  content: "";
  background-color: #cc003e;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.item:hover > *::after {
  opacity: 1;
}
