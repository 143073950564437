.container {
  position: relative;
}

.loader {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}
