.select {
  position: relative;
  background: white;
}

.select select {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 14px 16px;
  border-radius: 0;
  border: 1px solid #bfbfbf;
  background: transparent;
  font-family: inherit;
  outline: 0;
  appearance: none;
}

.select select:focus {
  border-color: #282e34;
}

.select .icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
