.container {
  position: static;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  padding: 0 0 0 16px;
  font-size: 14px;
}

.autoAttribution {
  display: none;
}
