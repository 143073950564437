.event-list-item {
	display: block;
	padding: 25px;
}

.event-list-item__stats {
	position: relative;
	font-size: 20px;
	padding-bottom: 50px;
}

.event-list-item__stats h3 {
	font-size: 0.6em;
	margin-bottom: 0.2em;
	font-family: var(--body-font);
}

.event-list-item__title {
	font-size: 20px;
	margin-bottom: 1.5rem;
	color: var(--text-color-darker);
}

.event-list-item__date {
	font-size: 14px;
	color: var(--text-color-darker);
}

.event-list-item__venue {
	font-size: 12px;
	color: var(--text-color-lighter);
}

.event-list-item__tickets-sold span {
	color: var(--text-color-lighter);
}

.event-list-item .Progress-bar {
	position: absolute;
	bottom: 5px;
	width: 50%;
}

.event-list-item__revenue {
	position: absolute;
	top: 0;
	left: calc(50% + 2em);
}

@media (min-width: 769px) {
	.event-list-item .Progress-bar {
		position: relative;
		bottom: auto;
		width: 100%;
		margin: 0.35em 0 0.8em;
	}

	.event-list-item__stats {
		padding-bottom: 0;
	}

	.event-list-item__revenue {
		position: relative;
		left: auto;
	}
}
