.container {
  position: relative;
  display: block;
  text-align: center;
  min-height: 1px;
  line-height: 2px;
}

.line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  background: #ebebeb;
}

.children {
  position: relative;
  display: inline-block;
  background: white;
  padding: 0 16px;
}

.children:empty {
  display: none;
}
