.text {
  color: var(--cta-color);
  text-decoration: underline;
  font-weight: bold;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

.text:focus:not(:active) {
  text-decoration: none;
  border-bottom: 2px dashed currentColor;
}
