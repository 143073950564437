.secondary[data-element="button"] {
  background: white;
  color: #cc003e;
}

.secondary [data-element="background"] {
  background: white;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.secondary [data-element="icon"] path {
  stroke: #cc003e;
}

.primary [data-element="loader"] circle {
  fill: #cc003e;
}
