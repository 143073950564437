.container {
  position: relative;
  animation: appear 0.5s ease;
}

.details {
  position: relative;
  margin-top: 16px;
}

.details [data-element="form-field-reset"] {
  top: -16px;
}

.map {
  position: relative;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
