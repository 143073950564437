.container {
  position: relative;
}

.container input[type="tel"] {
  padding-left: 60px;
}

.container :global(.react-phone-number-input__row) {
  position: relative;
  display: flex;
}

.container :global(.react-phone-number-input__country) {
  position: absolute;
  width: 60px;
  height: 100%;
}

.container :global(.react-phone-number-input__icon) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
}

.container :global(.react-phone-number-input__country select) {
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  background: transparent;
  border: 0;
}

.container :global(.react-phone-number-input__country-select-arrow) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container
  :global(select:focus + .react-phone-number-input__country-select-arrow) {
  border: 1px solid var(--color-input-border-focus);
}

.container img {
  display: block;
}
