.container {
  position: relative;
  text-align: center;
}

.icon svg {
  display: block;
  margin: 0 auto;
}

.children {
  margin: 16px 0 0;
}
