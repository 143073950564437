.portal {
  position: static;
}

.container {
  position: absolute;
  width: 100%;
  z-index: 101;
  background: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  animation: appear 0.15s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.items {
  max-height: 250px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.empty,
.item,
.extra {
  position: relative;
  padding: 16px;
  border-bottom: solid 1px #ebebeb;
  cursor: pointer;
  line-height: 1;
  height: 50px;
}

.item {
  display: flex;
  vertical-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  min-width: 0;
}

.item img {
  position: relative;
  display: block;
  top: -8px;
  left: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  object-fit: cover;
}

.empty,
.extra {
  cursor: default;
}

.extra button,
.extra a {
  color: var(--cta-color);
}

.item::before {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  content: "";
  background: var(--cta-color);
  transition: opacity 0.15s ease;
}

.itemContainer[data-has-focus="true"] .item::before {
  opacity: 0.65;
}

.item:hover::before {
  opacity: 1;
}

.item:hover {
  background-color: #f5f5f5;
}
