.Card {
	position: relative;
	border: 1px solid #eee;
	border-left: 0;
	background: #f5f5f5;
}

.Card .Button--Dropdown {
	position: absolute;
	right: 12px;
	bottom: 10px;
}

.Card__handle {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 5px;
	background: #6e6e6e;
}
