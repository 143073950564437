.container {
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  z-index: 5;
  background: white;
}

.header {
  position: relative;
  max-width: calc(960px + 32px);
  margin: 0 auto;
}

.header button {
  font-weight: normal;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 16px;
}

.logo {
  display: flex;
  position: relative;
  align-items: center;
  color: #7f7f7f;
  font-family: "Montserrat";
  font-size: 12px;
}

.branding {
  margin-left: 10px;
  text-transform: uppercase;
}

.logo svg {
  position: relative;
  display: block;
  width: 48px;
  height: auto;
}

.account {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f1f1f1;
}

.accountSeparator {
  display: none;
  width: 1px;
  height: 20px;
  margin-left: 10px;
  background-color: #bfbfbf;
}

.children {
  position: relative;
}

@media (min-width: 600px) {
  .header {
    /* padding: 0 32px; */
  }

  .accountSeparator {
    display: block;
  }

  .account {
    position: absolute;
    top: 50%;
    left: 82px;
    transform: translate(0, -50%);
    border-top: 0;
  }

  .logo {
    font-size: 16px;
  }

  .logo svg {
    width: 66px;
  }
}
