.input {
  display: block;
  width: 100%;
  padding: 15px 16px;
  font-size: 16px;
  line-height: 1;
  border: 1px solid var(--color-input-border);
  appearance: none;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
}

.input:focus {
  border-color: red;
  border: 1px solid var(--color-input-border-focus);
}
