.container {
  position: relative;
  padding: 16px 0;
}

.steps {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.step {
  position: relative;
  width: 26px;
  height: 26px;
}

.circle {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #ebebeb;
  background-color: white;
  border-radius: 13px;
  transition: border-color 0.4s ease,
    transform 0.35s cubic-bezier(0.18, 0.89, 0.65, 1.54);
  transition-delay: 0s;
}

.step.current .circle {
  transform: scale(1.308);
  border-color: white;
  transition-delay: 0.3s;
}

.step.current .circle::before,
.step.current .circle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  content: "";
  animation-delay: 0.2s;
}

.step.current .circle::before {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  animation: dot 0.6s ease 1 backwards;
  background-color: #cc003e;
}

.step.current .circle::after {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: calc(50% + 2px);
  border: 2px dashed #cc003e;
  animation: dashed 0.5s ease-out 1 backwards;
  animation-delay: 0.5s;
}

@keyframes dashed {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

@keyframes dot {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(5.65);
  }

  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

.step.past {
  border-color: #cc003e;
}

.step.past .circle {
  border-color: #cc003e;
}

.label {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: calc(100% + 18px);
  left: 50%;
  transform: translate3d(-50%, 0, 0) scale(1);
  padding: 6px;
  white-space: nowrap;
  background-color: #cc003e;
  color: white;
  border-radius: 2px;
  transition: all 0.25s ease;
  font-size: 11px;
  font-weight: bold;
}

.step.current .label {
  opacity: 1;
  visibility: visible;
  transform: translate3d(-50%, 0, 0) scale(1);
  transition-delay: 0.3s;
}

.step.inActive .label {
  opacity: 1;
  visibility: visible;
  background-color: #bfbfbf;
  color: white;
}

.step.inActive .label span {
  opacity: 1;
  transform: none;
}

.step.inActive .label::before {
  background-color: #bfbfbf;
}

.label::before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, 6px) rotate(-45deg);
  background-color: #cc003e;
  content: "";
}

.step:first-child .circle {
  transform-origin: 0 50%;
}

.step:last-child .circle {
  transform-origin: 100% 50%;
}

.step:last-child .label {
  left: auto;
  right: 0;
  transform: translate3d(0, 0, 0);
}
.step:last-child .label::before {
  left: auto;
  right: 12px;
  transform: translate(0, 6px) rotate(-45deg);
}

.step:first-child .label {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.step:first-child .label::before {
  left: 12px;
  transform: translate(0, 6px) rotate(-45deg);
}

.label span {
  display: block;
  position: relative;
  transform: translate3d(0, -50%, 0);
  transition: transform 0.5s ease, opacity 0.1s ease;
  transition-delay: 0.2s;
  opacity: 0;
}

.step.current .label span {
  transform: translate3d(0, 0%, 0);
  opacity: 1;
  transition-delay: 0.3s;
}

.bar {
  position: absolute;
  overflow: hidden;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
}

.barInner {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #cc003e;
  transition: all 0.5s ease-out;
  will-change: transform;
}

@media (min-width: 480px) {
  .label {
    font-size: 12px;
  }
}
