.container {
  display: inline-block;
  perspective: 600px;
  user-select: none;
}

.submit[data-element="button"] {
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 64px;
  line-height: 50px;
  height: 50px;
  transition: transform 0.08s ease;
  transform: translate3d(0, 0, 0);
  text-decoration: none;
}

.container[data-size="small"] .submit {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  padding: 0 16px;
}

.submit:hover,
.submit:focus {
  transform: translate3d(0, 0, 6px);
}

.submit[data-submitting="true"] {
  cursor: progress;
}

.submit:hover .background,
.submit:focus .background {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
  opacity: 0.85;
}

.submit:active {
  transform: translate3d(0, 0, -6px);
}

.submit:disabled:hover,
.submit:disabled:focus {
  transform: none;
}

.submit:active .background {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.12);
}

.submit:disabled:not([data-submitting="true"]) .background {
  background: rgb(151, 151, 151);
  opacity: 1;
  cursor: not-allowed;
}

.submit:disabled:not([data-submitting="true"]) .children {
  opacity: 0.25;
}

.submit[data-submitting="true"] .background {
  opacity: 1;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
}

.container[data-size="small"] .loader {
  transform: translate(-50%, -50%) scale(0.5);
}

.submit[data-submitting="true"] [data-element="children"] {
  opacity: 0;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #efefef;
  border-radius: 2px;
  transition: box-shadow 0.3s ease, opacity 0.2s ease;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.children {
  position: relative;
  white-space: nowrap;
}

.icon {
  position: absolute;
  right: 0;
  top: 50%;
}

.icon svg {
  display: block;
}
