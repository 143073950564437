.portal [data-element="modal-container"] {
  justify-content: flex-end;
}

.slideIn {
  position: relative;
  width: 100%;
  max-height: 100%;
  padding: 32px 0;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  overflow-y: scroll;
}

.close {
  position: absolute;
  z-index: 1;
  top: 18px;
  right: 18px;
}

/* .slideIn::-webkit-scrollbar {
  -webkit-appearance: none;
  background: transparent;
  width: 11px;
}
.slideIn::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
} */

@media (min-width: 600px) {
  .portal [data-element="modal-container"] {
    align-items: flex-end;
  }

  .slideIn {
    width: auto;
    min-height: 100%;
    max-height: 100%;
  }

  .slideIn form {
    min-width: 300px;
  }
}

.children {
  position: relative;
  padding-top: 52px;
}
