.container {
  position: relative;
}

.container a {
  text-decoration: underline;
}

.header {
  position: relative;
  margin-bottom: 32px;
  top: 0;
  z-index: 2;
  width: 100%;
  background: white;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
}

.body {
  overflow: hidden;
}

.disclaimer {
  text-align: center;
  padding: 0 32px;
  margin-top: 80px;
  font-size: 14px;
}
