.container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 16px;
  line-height: 1.1;
}

.container .baseUrl {
  border-right: 0;
  padding-right: 0;
  color: #b1b1b1;
  min-width: 0;
  width: auto;
  border: 1px solid #bfbfbf;
  border-right: 0;
  height: 50px;
  padding: 16px 0 16px 16px;
}

.container .field[type="text"] {
  height: 50px;
  border-left: 0;
  flex: 1;
  padding-left: 0;
  min-width: 0;
}

.container .field[type="text"]:focus + div {
  border-color: #282e34;
}

.container .field[type="text"]:read-only + div {
  border-color: #eaeaea;
}

[data-element="field"][data-admin-field="true"] .container .field + div {
  border-color: var(--admin-color);
}

[data-element="field"][data-has-error="true"] .container .field + div {
  border-color: var(--error-color);
}
