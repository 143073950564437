.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  max-width: 100%;
}

.notification {
  position: relative;
  overflow: hidden;
  width: calc(100vw - 32px);
  margin: 16px 16px 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0 rgba(40, 46, 52, 0.2);
  cursor: pointer;
}

.notification[data-category="success"] {
  box-shadow: 2px 2px 8px 0 rgba(46, 164, 255, 0.3);
}

.notification[data-category="error"] {
  box-shadow: 2px 2px 8px 0 rgba(204, 0, 62, 0.2);
}

.notification .inner {
  padding: 0 16px;
}

.notification[data-category="info"] .inner {
  background: #7f7f7f;
}

.notification[data-category="success"] .inner {
  background: #2ea4ff;
}

.notification[data-category="error"] .inner {
  background: #cc003e;
}

.notification[data-category="error"] a,
.notification[data-category="error"] button {
  font-size: inherit;
  padding: 0;
  display: inline;
  color: inherit;
  -webkit-appearance: none;
  background: inherit;
  border: none;
  font-weight: bold;
  font-family: inherit;
  text-decoration: underline;
  outline: 0;
  cursor: pointer;
}

.inner {
  position: relative;
  display: flex;
  align-items: center;
}

.inner > * {
  position: relative;
  z-index: 1;
}

.timer {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  content: "";
  transform: translate3d(calc(-100% + 8px), 0, 0);
}

.notification[data-category="error"] .icon svg {
  animation: flash 0.4s ease 3;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.inner a {
  color: white;
  text-decoration: underline;
}

.icon {
  display: flex;
  justify-content: center;
  margin-right: 16px;
}

.icon svg {
  width: 20px;
  height: auto;
  display: block;
}

.dismiss {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}

.dismiss button {
  display: block;
  padding: 0;
  transition: all 0.1s ease;
}

.dismiss button svg {
  display: block;
}

.children {
  flex: 1;
  padding: 16px 0;
}

.inner:hover .dismiss button {
  transform: scale(1.2);
}

@media (min-width: 450px) {
  .notification {
    max-width: 288px;
    margin: 16px 16px 0;
  }
}
