.container {
  position: relative;
  display: flex;
  align-items: center;
}

.container > div:first-child {
  flex: 1;
}

.container > div:nth-child(2) {
  padding: 0 8px;
}

.container .timeContainer {
  position: relative;
  flex-basis: 88px;
}

.container .timeContainer :global(.react-datepicker-popper) {
  min-width: 0;
}

.container .timeContainer :global(.react-datepicker) {
  padding: 0;
}

:global(.react-datepicker__time-container) {
  position: relative;
}

:global(.react-datepicker__time-list) {
  height: 150px;
  overflow: auto;
}

:global(.react-datepicker__header--time) {
  display: none;
}

:global(.react-datepicker__time-list) {
  list-style-type: none;
  margin: 0;
  padding: 16px;
}

:global(.react-datepicker__time-list li) {
  padding: 4px 0;
}

:global(.react-datepicker__day--keyboard-selected),
:global(.react-datepicker__day--selected) {
  background-color: #cc003e;
}
