.copy-to-clipboard {
  position: relative;
  display: flex;
}

.copy-to-clipboard .Button {
  position: relative;
  padding: 0 1em;
  min-height: 0;
}

.copy-to-clipboard .input-field {
  flex: 1;
  width: auto;
  padding-right: 0;
}
